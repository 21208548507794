import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";
import NoSleep from "nosleep.js";
var noSleep = new NoSleep();
import { NotificationProgrammatic as Notification } from "buefy";

// HOTFIX
// TODO: Crear vuei18n de manera estática o tipo singleton
// Asi no lo estamos creando en cada store
import VueI18n from 'vue-i18n';
const whitelabelConfig = require('@/whitelabel.config');
Vue.use(VueI18n)

// i18n locales
import messages from '@/translation/locales.json';
const i18n = new VueI18n({
  locale: whitelabelConfig.defaultLanguage || 'en',
  fallbackLocale: 'en',
  messages,
})

Vue.prototype.i18n = i18n


Vue.use(Vuex);
Vue.prototype.$baseUrl = process.env.VUE_APP_API_BASE_URL;

export default new Vuex.Store({
  state: {
    socketConnectedOnce: false,
    duplicateConnection: false,
    loading: true,
    mode: "", // "user" o "screen"
    route: null,
    routeFrom: null,
    setupLoaded: false,
    setup: {},
    avatars: [],
    talkingPointsShow: false,
    talkingPoints: {title: null, content: null},
  },
  getters: {
    getRoute(state, getters, rootState) {
      return rootState.route;
    },
    playlist(state) {
      return state.setup.playlist.content;
    },
    menuItems(state) {
      return state.setup.playlist.content.filter(
        (x) => x.experience && !x.hideInMenu
      );
    },
    remoteItems(state) {
      // const itemsWithoutCat = state.playlist.filter(x => !x.category)
      return [
        {
          id: "menu",
          shortcutExperiences: [],
          space: {
            id: "menu",
            slug: "menu",
            title: "Menu",
            section: {
              title: "",
              order: -1,
            },
          },
          experience: {
            id: "menu",
            slug: "menu",
            title: "Menu",
          },
        },
        ...state.setup.playlist.content
          .filter((x) => x.experience)
          .sort(function (a, b) {
            if (!a.space.section) {
              return -1;
            }
            if (b.space.section.order > a.space.section.order) {
              return -1;
            }
            if (b.space.section.order < a.space.section.order) {
              return 1;
            }
            return 0;
          }),
      ];
    },
    menuSections(state) {
      const sections = state.setup.playlist.content
        .filter((x) => x.experience && !x.hideInMenu)
        .map((x) => x.space.section)
        .sort(function (a, b) {
          if (b.order > a.order) {
            return -1;
          }
          if (b.order < a.order) {
            return 1;
          }
          return 0;
        });

      const filterArray = (arr) =>
        Object.values(
          arr.reduce((acum, item) => {
            acum[item.id] = item;
            return acum;
          }, {})
        );
      return filterArray(sections);
    },
  },
  mutations: {
    TalkingPointsShow(state, data){
      state.talkingPointsShow = true;
      state.talkingPoints.title = data.title;
      state.talkingPoints.content = data.talkingPoints;
    },
    TalkingPointsHide(state){
      state.talkingPointsShow = false;
    },
    SET_ROUTE(state, {to, from}) {
      // console.log(to, from)
      state.route = to;
      state.routeFrom = from;
    },
    SOCKET_CONNECT(state) {
      state.socketConnectedOnce = true;
    },
    SOCKET_DISCONNECT(state, reason) {
      console.log("DISCONNECT", reason);
      if (reason.includes("io server disconnect")) {
        console.log("Duplicate connection");
        state.duplicateConnection = true
        router.push({ path: "duplicate" });
      }
    },
    StartLoading(state) {
      state.loading = true;
    },
    StopLoading(state) {
      state.loading = false;
    },
    SetClientMode(state, mode) {
      state.mode = mode;
    },
    // SOCKET_SETUPCHANGED(state, data) {
    //   console.log(data);
    //   state.setup = data;
    // },
    // SOCKET_PLAYLISTCHANGED(state, data) {
    //   console.log("Playlist changed", data);
    //   state.playlist = [];
    //   setTimeout(() => {
    //     state.playlist = data;
    //   }, 200);
    // },
    // SOCKET_EXPERIENCEEDITED(state, experienceId) {
     
    // },
    SOCKET_SPACEEDITED(state, spaceId) {
      console.log("space edited", spaceId);
      axios
        .get(process.env.VUE_APP_API_BASE + `/api/spaces/${spaceId}`)
        .then((newSpace) => {
          let currSpace = state.setup.playlist.content.find(
            (x) => x.space.id == spaceId
          );
          if (currSpace) {
            const i = state.setup.playlist.content.indexOf(currSpace);
            state.setup.playlist.content[i].space = newSpace.data;
            Vue.prototype.$sfxChangeVolume(state.setup.playlist.content[i].space.volume/100)
          }
        });
    },
    SaveSetup(state, data) {
      console.log("setup data", data);
      state.setup = data;
      state.setupLoaded = true;
    },
    SaveAvatars(state, data) {
      state.avatars = data;
    },
    SOCKET_CONNECTEDSCREENSUPDATE(state, data) {
      const space = state.setup.playlist.content.find(
        (x) => x.space.slug == data.spaceSlug
      );
      if (space) {
        space.screensConnected = data.screensConnected;
      }
    },
  },
  actions: {
    socket_experienceEdited(ctx, experienceId){
      console.log("experience edited", experienceId);
      axios
        .get(process.env.VUE_APP_API_BASE + `/api/experiences/${experienceId}`)
        .then((newExp) => {
          let currExp = ctx.state.setup.playlist.content.find(
            (x) => x.experience.id == experienceId
          );
          if (currExp) {
            const i = ctx.state.setup.playlist.content.indexOf(currExp);
            ctx.state.setup.playlist.content[i].experience = newExp.data;
          }

          if (ctx.state.mode == "screen") {
            const isThisExperience = ctx.getters["space/config"].experience.id == experienceId;
            if(!isThisExperience) return;
             Notification.open({
              message: Vue.prototype.i18n.t('notifications.experienceConfigUpdated'),
              position: "is-bottom-right",
              type: "is-primary",
            });
            setTimeout(() => {
              location.reload();
            }, 1500);
          }
        });
    },
    enableNoSleep() {
      noSleep.enable();
    },
    disableNoSleep() {
      noSleep.disable();
    },
    socket_movedToMenu() {
      Notification.open({
        message: i18n.t('notifications.movedToMenu'),
        type: "is-success"
      });
    },
    socket_showMessage(ctx, data) {
      
      Notification.open({
        message: `${data.message}`,
        type: "is-primary",
      });
    },
    socket_setupEdited(ctx) {
      console.log("Setup Edited");
      ctx.dispatch("LoadSetup");
    },
    async LoadAvatars() {
      console.log("Loading Avatars");
      return new Promise((resolve, reject) => {
        try {
          axios
            .get(process.env.VUE_APP_API_BASE + "/api/globals/avatars")
            .then(({ data }) => {
              this.commit("SaveAvatars", data.content);
              resolve();
            });
        } catch (e) {
          console.log("❗️ Error loading avatars");
          reject();
        }
      });
    },
    async LoadSetup() {
      console.log("Loading Setup");
      return new Promise((resolve, reject) => {
        try {
          axios
            .get(process.env.VUE_APP_API_BASE + "/api/globals/setup?depth=3")
            .then(({ data }) => {
              data.playlist.content.forEach((room) => {
                if (room.experienceOverride) {
                  room.experience = room.experienceOverride;
                }
              });
              this.commit("SaveSetup", data);
              // Aca no se si guardar todo el data en un campo setup
              // O si guardar cada cosa por separado?
              resolve();
            });
        } catch (e) {
          console.log("❗️ Error on load setup");
          reject();
        }
      });
    },
  },
});
