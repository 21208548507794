<template>
  <div id="app">
    <!-- Este template hostea tanto la interfaz mobil como las screens -->
    <b-loading v-model="$store.state.loading">
      <div class="loading-screen">
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90C69.8528 90 90 69.8528 90 45ZM13.5 45C13.5 27.603 27.603 13.5 45 13.5C62.397 13.5 76.5 27.603 76.5 45C76.5 62.397 62.397 76.5 45 76.5C27.603 76.5 13.5 62.397 13.5 45Z"
            fill="#CCCCCC" />
          <path class="primary"
            d="M90 45C90 50.9095 88.836 56.7611 86.5746 62.2208C84.3131 67.6804 80.9984 72.6412 76.8198 76.8198C72.6412 80.9984 67.6804 84.3131 62.2208 86.5746C56.7611 88.836 50.9095 90 45 90L45 76.5C49.1366 76.5 53.2328 75.6852 57.0545 74.1022C60.8763 72.5192 64.3488 70.1989 67.2739 67.2739C70.1989 64.3488 72.5192 60.8763 74.1022 57.0545C75.6852 53.2328 76.5 49.1366 76.5 45H90Z"
            fill="var(--primary)" />
        </svg>
      </div>
    </b-loading>

    <template v-if="$store.state.mode == 'player' && $store.state.setupLoaded && validRouteForHeader">
      <header v-if="$store.state.user.profile.id" @click="$store.commit('user/EditProfile')">
        <user-header></user-header>
      </header>
      <profile-editor v-if="$store.state.user.profile.id" :key="$store.state.user.profile.email" />
      <profile-verify v-if="$store.state.user.profile.id" />
    </template>

    <transition name="route" mode="out-in">
      <router-view v-if="$store.state.user || $store.state.space" id="room" :class="roomClass()"></router-view>
    </transition>

    <footer v-if="$store.state.mode == 'player'" class="app-footer p-1 has-text-centered is-size-7"
      :style="{ borderColor: '#' + $store.state.user.profile.color }">
      <template v-if="$store.state.setup.footer.text">
        <json-rich-text :text="$store.state.setup.footer.text" />
      </template>
      <template v-else>
        © 2022. Created with <a href="http://douob.online" class="has-text-weight-bold" target="_blank">douob.online</a>
        by <a href="http://verne.studio" class="has-text-weight-bold" target="_blank">verne.studio</a>
      </template>
    </footer>

    <template v-if="$store.getters['user/hasAdminAuthority'] && $store.hasModule('admin')">
      <div id="adminButton" class="has-background-primary has-text-white"
        @click="$store.commit('admin/showRemote', true)">
        <b-icon pack="fas" icon="gear" size="is-small"></b-icon>
      </div>
    </template>

    <!-- INVITE EXPERIENCE MODAL -->
    <b-modal v-model="ShowExperienceInviteModal" :can-cancel="false">
      <div class="modal-card mx-5" style="width: auto">
        <header class="modal-card-head has-background-white">
            <!-- traducir -->
            <p class="modal-card-title is-size-5">Invite</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <template v-if="$store.state.user && $store.state.user.experienceInviteData">
            {{$store.state.user.experienceInviteData.text}}
          </template>
        </section>
        <footer class="modal-card-foot">
            <b-button
              :label="$t('system.close')"
              type="is-primary"
              size="is-small"
              outlined
              expanded
              @click="$store.commit('user/ExperienceInviteHide')"/>
            <b-button
              label="Join"
              type="is-primary"
              size="is-small"
              expanded
              @click="$store.dispatch('user/EnterExperience')"/>
        </footer>
     </div>
    </b-modal>

    <admin-remote v-if="this.$store.getters['user/hasAdminAuthority'] && $store.hasModule('admin')" />

    <b-notification v-if="
      $store.state.socketConnectedOnce &&
      !$socket.connected &&
      !$store.state.loading && !$store.state.duplicateConnection"
      id="connectionError"
      type="is-warning"
      has-icon
      icon="rotate"
      icon-size="is-small fa-spin"
      role="alert"
      :closable="false"
      :message="$t('notifications.connectionError')"/>

    <div id="cosaspreload" style="display: none">
      <img v-for="(av, i) in $store.state.avatars" :key="i" :src="av.image.sizes.thumbnail.url" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import userState from "@/store/user";
import spaceState from "@/store/space";
import adminState from "@/store/admin";
import JsonRichText from '@/components/JsonRichText.vue';
const whitelabelConfig = require("@/whitelabel.config.js");

export default {
  components: {
    AdminRemote: () => import("@/components/AdminRemote.vue"),
    JsonRichText
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ShowExperienceInviteModal(){
      if(!this.$store.state.user) return false
      if(this.$store.state.user.experienceInviteData == null) return false
      return this.$store.state.user.experienceInviteShow || false
    },
    validRouteForHeader() {
      return this.$route.name == "menu" || this.$route.name == "spaceController"
    },
    hasMobileBackground(){
        return this.$store.state.setup.mobileBackground ? true : false
    },
    mobileBackground() {
      return this.hasMobileBackground ? 'background-image: url('+encodeURI(this.$store.state.setup.mobileBackground.url)+')' : ''
    }
  },
  methods: {
    Reload() {
      document.location.reload()
    },
    roomClass() {
      return `route_${this.$route.name}`
    },
  },
  sockets: {
  },
  mounted: async function () {
    if(whitelabelConfig.favicon){
      const favicon = document.getElementById("favicon");
      favicon.href = whitelabelConfig.favicon;
      console.log("Changing favicon to", whitelabelConfig.favicon)
    }

    console.log("📱 DOUOB FRONTEND")
    this.$store.commit("StartLoading");
    this.$store.dispatch("LoadAvatars");
    await this.$store.dispatch("LoadSetup");
    // TODO juntar LoadAvatars y LoadSetup en un axios.all para descargar en paralelo
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios

    // const userRoutes = ["menu", "spaceController", "login", "signup"];
    // console.log("this.$route.name", this.$store.state.route)
    const screenRoutes = ["spaceScreen", "spaces"] ;
    if (screenRoutes.includes(this.$store.state.route.name)) {
      
      // 📺 SCREEN MODE
      console.log("📺 SCREEN MODE")
      if (!this.$store.hasModule("space")) {
        this.$store.registerModule("space", spaceState); // dynamic vuex module
        console.log("Loaded space vuex module")
      }
      this.$store.commit("SetClientMode", "screen");
    } else {
      // 🕹 PLAYER MODE
      if (!this.$store.hasModule("user")) {
        console.log("agregando vuex user")
        this.$store.registerModule("user", userState); // dynamic vuex module
      }
      this.$store.commit("SetClientMode", "player");
      await this.$store.commit("user/LoadPlayerEmailToken");
      if (this.$store.state.user.token) {
        await this.$store.dispatch("user/GetPlayer").catch(
          () => {
            this.$store.dispatch("user/GetPlayer")
          })
        if (this.$store.getters["user/hasAdminAuthority"]) {
          // 👑 ADMIN MODE Store layer
          if (!this.$store.hasModule("admin")) {
            this.$store.registerModule("admin", adminState); // dynamic vuex module
            this.$store.commit("admin/GetRawData");
          }
        }
        this.$socket.client.open();

      } else {
        // MANUAL LOGIN / REGISTER
        this.$store.commit("StopLoading");
        console.log("Usuario no encontrado. A registrarse")
        // await this.$store.commit("user/SetAccountState", 2); // register
        // console.log(this.$router, {query: { redirect: this.$route.path }})
        if (this.$route.name != "signup" && this.$route.name != "login") {
          // const redirect = this.$route.name != "signup" || this.$route.path != "login" ? this.$route.path.replace("/", "") : "";
          this.$router.push({ path: '/signup', query: { redirect: this.$route.path.replace("/", "") } })
        }
      }
      // Player sfx load
      this.$sfxLoad()
    }

    // Timesync
    var Timesync = require('timesync')
    Vue.prototype.$time = Timesync.create({
      server: this.$socket.client,
      interval: 5000,
    });
    this.$time.send = function (socket, data, timeout) {
      //console.log('send', data);
      return new Promise(function (resolve, reject) {
        var timeoutFn = setTimeout(reject, timeout);
        socket.emit('timesync', data, function () {
          clearTimeout(timeoutFn);
          resolve();
        });
      });
    };

  },
};
</script>