import Vue from "vue";
import { defineAsyncComponent } from 'vue'

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import App from "./App.vue";
// eslint-disable-next-line
import { sync } from "vuex-router-sync";
import router from "./router";
import store from "./store";

const whitelabelConfig = require('@/whitelabel.config');
// const unsync = sync(store, router); // https://github.com/vuejs/vuex-router-sync

import Buefy from "buefy";
// Sistema white label diseñado según esto:
// https://www.nickmcburney.co.uk/blog/vuejs-whitelabel
import "./styles/main.scss";

// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
// internal icons
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, far, fab);
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

// global components
Vue.component("UserHeader", defineAsyncComponent(() =>
  import("./components/UserHeader.vue")
));
Vue.component("MenuComponent", defineAsyncComponent(() =>
  import("./components/MenuComponent.vue")
));

Vue.component("PlayerName", defineAsyncComponent(() =>
  import("./components/PlayerName.vue")
));
Vue.component("ProfileEditor", defineAsyncComponent(() =>
  import("./components/account/ProfileEditor.vue")
));

Vue.component("ProfileVerify", defineAsyncComponent(() =>
  import("./components/account/ProfileVerify.vue")
));

Vue.component("PlayerAvatar", defineAsyncComponent(() =>
  import("./components/PlayerAvatar.vue")
));
import HeaderLogo from "./components/account/HeaderLogo";
Vue.component("HeaderLogo", HeaderLogo);

// import AdminRemote from "./components/AdminRemote.vue";
Vue.component("AdminRemote", defineAsyncComponent(() =>
import("./components/AdminRemote.vue")
));

Vue.mixin({
  methods: {
    // Para usar dentro de un async
    // Genera una pausa en cualquier momento
    // await this.$timeout(4000);
    $timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    $camelToWords(camel) {
      const result = camel.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
  },
});

Vue.prototype.$ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

// Socket IO: https://github.com/probil/vue-socket.io-extended
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
// import { createNamespacedHelpers } from "vuex";
const socket = io(process.env.VUE_APP_SOCKET_URL, {
  autoConnect: false,
});
Vue.use(VueSocketIOExt, socket, { store });

Vue.config.productionTip = true;
Vue.prototype.$baseUrl = process.env.VUE_APP_API_BASE;

// i18n locales
import messages from './translation/locales.json';
const i18n = new VueI18n({
  locale: whitelabelConfig.defaultLanguage || 'en',
  fallbackLocale: 'en',
  messages,
  // silentTranslationWarn: true
})

// Douob sounds
import DouobSounds from './components/DouobSounds';
Vue.use(DouobSounds)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
