module.exports = {
    defaultLanguage: 'es',
    scssVariables: './src/styles/fypf2022',
    randomNickname:  () => { return RandomNickname() },
    profileColor: {saturation: 90, brighness: 65},
    favicon: 'fypffavicon.ico'
}

function RandomNickname() {
    const animales = ["GORRION", "LEON", "PAJARO", "LORO", "CABALLO", "AGUILA", "HUEMUL", "LLAMA", "PERRO", "GATO", "CAMELLO", "FOCA", "JABALI", "RANA", "DELFIN", "GIRAFA", "CASTOR", "ALCON", "ZORRO", "LORO", "SURICATA"];
    const adjetivos = ["RECICLAD", "ECO", "ELEGANTE", "FIEL", "JUVENIL", "AMENO", "FLEXIBLE", "SERVIL", "ALEGRE", "DULCE", "AMABLE", "NOTABLE", "SUAVE", "INQUIETO", "MODERNO", "ENORME", "NOBLE", "FEROZ", "MUSICAL", "CULTURAL", "GENIAL"];
    const rand1 = Math.round(Math.random() * 99999);
    const animal = animales[rand1 % animales.length];
    const rand2 = Math.round(Math.random() * 99999);
    const adjetivo = adjetivos[rand2 % adjetivos.length];
    return animal + " " + adjetivo;
}

