import Vue from "vue";
import { NotificationProgrammatic as Notification } from "buefy";
// const unsync = sync(store, router); // https://github.com/vuejs/vuex-router-sync

const States = Object.freeze({
  waitingPlayers: 0,
  starting: 1,
  playing: 2,
  finalScore: 3,
});

export default {
  namespaced: true,
  state: () => ({
    connectedOnce: false,
    spaceSlug: "",
    serverState: 0,
    players: [],
    newPlayersExtraData: {}, // Cada pantalla guarda aca datos para agregar a cada jugador nuevo
    countdownTs: null,
  }),
  mutations: {
    SpaceSlug(state, mySlug) {
      state.spaceSlug = mySlug;
      state.connectedOnce = true;
    },
    SOCKET_SPACESTATE(state, data) {
      console.log("💡 Space state changed", data.state);
      state.serverState = data.state;
    },
    // SOCKET_STARTCOUNTDOWN(state, data) {
    //   // Quizas sacarlo de aca y dejarlo solo en las experiencias?
    //   // console.log("⏳ Start countdown", data);
    //   // state.countdownTs = data.timestamp;
    //   // console.log("restante", state.countdownTs - Date.now());
    // },
    SOCKET_STARTMATCH(state) {
      console.log("🚀 Start match");
      state.serverState = States.playing;
    },
    SOCKET_PLAYERLIST(state, players) {
      console.log("🏄🏄 Received player list", players);
      players.forEach((player) => {
        // Le agregego la extra data a cada jugador
        Object.keys(state.newPlayersExtraData).forEach((key) => {
          const data = JSON.parse(
            JSON.stringify(state.newPlayersExtraData[key])
          );
          player[key] = data;
        });
      });
      state.players = players;
    },
    SOCKET_PLAYERJOINED(state, player) {
      console.log("🏄 Player joined", player);
      // Avoid accidental duplicated
      // TODO REVISAR !!
      if (!state.players.find((x) => x.dbid == player.dbid)) {
        // Le agregego la extra data
        Object.keys(state.newPlayersExtraData).forEach((key) => {
          player[key] = state.newPlayersExtraData[key];
        });
        state.players.push(player);
      }
    },
    SOCKET_PLAYERLEFT(state, data) {
      try {
        const p = state.players.find((x) => x.dbid == data.dbid);
        if (p) {
          const i = state.players.indexOf(p);
          state.players.splice(i, 1);
        }
      } catch (e) {
        console.warn("❗️ No se pudo borrar usuario", e);
      }
    },
    SOCKET_DEQUEUED(state, data) {
      console.log("dequed", data);
      state.players
        .filter((x) => data.ids.includes(x.dbid))
        .map((x) => (x.queue = false));
    },
    SOCKET_PLAYEREDITED(state, changes) {
      console.log("Player changed profile", changes.username);
      const player = state.players.find((x) => x.dbid == changes.dbid);
      if (player) {
        for (const key in changes) {
          Vue.set(player, key, changes[key]);
        }
      }
    },
    AddPlayerData(state, data) {
      // console.log("adding player data", data);
      const player = state.players.find((x) => x.dbid == data.dbid);
      if (player) {
        Object.keys(data).forEach((key) => {
          if (key != "dbid") {
            player[key] = data[key];
          }
        });
      } else {
        console.log("SetPlayerData: Player not found", data);
      }
    },
    NewPlayersData(state, _data) {
      const data = JSON.parse(JSON.stringify(_data));
      state.newPlayersExtraData = data;
    },
  },
  actions: {
    socket_connect() {
      console.log("🦄 Screen connected to socket");
      // TODO API SERVER LOGIN

    },
    socket_spaceEdited(ctx, spaceId) {
      if (ctx.getters.config.space.id == spaceId) {

        console.log("🦄 ",Vue.prototype.i18n.t('notifications.spaceConfigUpdated'))

        Notification.open({
          message: Vue.prototype.i18n.t('notifications.spaceConfigUpdated'),
          position: "is-bottom-right",
          type: "is-success"
        });
      }
    },
    // socket_experienceEdited(ctx, experienceId) {
    //   if (ctx.getters.config.experience.id == experienceId) {
    //     console.log("🦄 ",Vue.prototype.i18n.t('notifications.experienceConfigUpdated'))

    //     Notification.open({
    //       message: Vue.prototype.i18n.t('notifications.experienceConfigUpdated'),
    //       position: "is-bottom-right",
    //       type: "is-primary",
    //     });
    //   }
    // },
    socket_refreshScreen() {
      console.log("Reiniciando pantalla");
      window.location.reload();
    },
    socket_closeConnection(){
      window.location = "/spaces/"
    },
    SetNewPlayersExtraData(ctx, extraData) {
      console.log("Set new players extra data", extraData);
      ctx.commit("NewPlayersData", extraData);
      if (ctx.state.players.length) {
        console.log("Setting data to previous players");
        ctx.state.players.forEach((player) => {
          ctx.commit("AddPlayerData", {
            dbid: player.dbid,
            ...extraData,
          });
        });
      }
    },
  },
  getters: {
    config: (state, getters, rootState, rootGetters) => {
      return rootGetters.playlist.find((x) => x.space.slug == state.spaceSlug);
    },
    queue: (state) => {
      // return state.players;
      return state.players.filter((x) => x.queue);
    },
    serverStateString: (state) => {
      return Object.keys(States)[state.serverState];
    },
  },
};
