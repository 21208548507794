const FYPF2022_options      = require("./styles/fypf2022/options")
const DOUOB_options         = require("./styles/douob/options")
const LEHMANN_options       = require("./styles/lehmann/options")
const SGPSEEDCARE_options   = require("./styles/sgpseedcare/options")

const options = {
    'SGPSEEDCARE': SGPSEEDCARE_options,
    'SYNFIELDVISIT': SGPSEEDCARE_options, // Falta crear su propio setting
    'DOUOB': DOUOB_options,
    'FYPF2022': FYPF2022_options,
    'LEHMANN': LEHMANN_options,
}

module.exports = options[process.env.VUE_APP_PRODUCT_THEME]